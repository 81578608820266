
import React from 'react';
import PropTypes from 'prop-types';
//import { DiscussionEmbed } from 'disqus-react';
import { graphql } from 'gatsby';

import BlockContent from 'molecules/BlockContent';
import Container from 'molecules/Container';
import Image from 'molecules/Image';
import Link from 'molecules/Link';
import MajorPostSnippet from 'organisms/MajorPostSnippet';
import SEO from 'molecules/SEO';
import ShareButtons from 'molecules/ShareButtons';

import { client as sanity } from 'lib/sanity';

import { postMetaFields } from 'lib/fragments';

import './SinglePost.scss';

const BlockRenderer = p => {

  const { style = 'normal' } = p.node;

  if( style === 'xs' || style == 'sm' || style === 'lg' || style === 'xl'  ) {

    return (
      <p className={ `p-${style}` }>
        { p.children }
      </p>
    )
  }

  if( /^h\d/.test( style ) ) {

    const level = style.replace( /[^\d]/g, '' );
    let size = 'md';

    if( level === '3' ) {
      size = 'sm';
    }
    else if( level === '4' ) {
      size = 'xs';
    }
    else if( level === '5' ) {
      size = 'xs';
    }
    else if( level === '6' ) {
      size = 'xs';
    }

    return React.createElement( style, { className: `h-${size}`}, p.children );

  }

  if( style === 'blockquote' ) {
    return <blockquote>{ p.children }</blockquote>
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block( p );

}

const contentSerializers = {
  types: {
    block: BlockRenderer,
    pageImage: p => {

      return(
        <Image
        alt={ p.node.altText }
        data={ p.node.image.asset }
        originalSize={ p.node.originalSize }
        />
      );

    },
    code: ( { node } ) => {

      return(
        <div
        className="Code"
        dangerouslySetInnerHTML={ { __html: node.code } } 
        />
      )
    }
  },
}

/**
 * Single Post.
 */
export const query = graphql`
  query Post( $id: String ) {
    sanityPost( id: { eq: $id } ){
      id
      author {
        id
        name
        slug {
          current
        }
      }
      category {
        name
        slug {
          current
        }
        _id
      }
      content: _rawContent(resolveReferences: {maxDepth: 3})
      datePublished(formatString: "MMMM Do, YYYY")
      featuredImage {
        asset {
          metadata {
            lqip
          }
          url
        }
      }
      smallImage
      featImageAlt
      overview {
        bulletPoints
        heading
      }
      slug {
        current
      }
      summary
      title
      ...postMetaFields
    }

  }
`;
export default function SinglePost( { data, path } ) {

  const  { sanityPost: post, sharing } = data;

  const [ nextPost, setNextPost ] = React.useState( null );
  return(

    <article
    className="Post"
    itemprop="blogPost"
    itemscope
    itemtype="https://schema.org/BlogPosting"
    >

      <SEO
      title={ post.title }
      path={ path }
      { ...post.meta }
      />

      <header>

        <Image
        className={ `Post__img ${!!post.smallImage ? 'is-sm' : '' }` }
        data={ post.featuredImage.asset }
        alt={ post.featImageAlt }
        />

        <Container
        className="Post__top"
        size="lg"
        >
          <Container size="sm">

          <div className="Post__top-cats">
              { !! post.category &&
                <span
                rel="category tag"
                key={ post.category.slug.current }
                className="cat f-tag d-inline-block"
                >
                  { post.category.name }
                </span>
              }
            </div>

            <h1
            className="Post__top-hding h-serif-xl"
            itemprop="name"
            >
              { post.title }
            </h1>

            <div
            className="Post__top-author p-lg"
            itemprop="author"
            itemscope
            itemtype="https://schema.org/Person"
            >
              <Link
              rel="author"
              itemprop="url"
              href="#"
              title="View author posts"
              to={ `/blog/author/${ !! post.author ? post.author.slug?.current : '' }` }
              >
                <span itemprop="name">
                  By <strong>{ !! post.author ? post.author.name : '' }</strong>
                </span>
              </Link>
            </div>

            <div
            className="Post__top-date p-lg d-inline-block"
            >
              <time
              datetime={ post.datePublished }
              itemprop="datePublished"
              >
                { post.datePublished }
              </time>

            </div>

            { !! post.overview.bulletPoints.length &&

              <div
              className="Post__top-oview"
              itemprop="description"
              >
                <h2 className="hding h-serif-s,">{ post.overview.heading }</h2>
                <ul className="cleanlist">
                  { post.overview.bulletPoints.map( ( item, i ) => (
                    <li
                    key={ i }
                    className="item"
                    >
                      { item }
                    </li>
                  ) ) }
                </ul>
              </div>

            }

          </Container>

        </Container>
      </header>

      <div className="Post__content-shareside d-none d-lg-block">
        <div className="inner">
          <ShareButtons post={ post } />
        </div>
      </div>

      <Container
      className="Post__content p-lg"
      itemprop="articleBody"
      size="lg"
      >
        <Container size="sm">

          <BlockContent
          blocks={ post.content }
          serializers={ contentSerializers }
          />

          <div className="Post__content-sharebottom d-lg-none">
            <ShareButtons post={ post } />
          </div>

          { /*
          { typeof window !== 'undefined' &&

            <div className="Post__content-disqus">
              <DiscussionEmbed
              shortname="epsilonmgmt-demo"
              config={ {
                url: window.location.href,
                identifier: post.id,
                title: post.title,
              } }
              />
            </div>

          }
          */ }

        </Container>
      </Container>

    </article>

  );

};


