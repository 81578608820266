import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Image from 'molecules/Image';
import Link from 'molecules/Link';

import './MajorPostSnippet.scss';

/**
 * Major post snippet.
 */
const propTypes = {
  post: PropTypes.post,
}
const MajorPostSnippet = p => {

  const className = classnames(
    'MajorPostSnippet',
    p.className
  )

  return(

    <article
    className={ className }
    itemscope="itemscope"
    itemtype="http://schema.org/BlogPosting"
    itemprop="blogPost"
    >
      <div className="row">

        <div className="MajorPostSnippet__img col-md-6">
          <Link
          to={ `/blog/${p.post.slug.current}` }
          className="d-block inner"
          >
            <Image
            data={ p.post.featuredImage.asset }
            alt={ p.post.featImageAlt }
            itemprop="image"
            />
          </Link>
        </div>

        <div className="MajorPostSnippet__content col-md-6">
          <header className="hder">
            <div className="cats">
              { !! p.post.category &&
                <Link
                className="f-tag d-inline-block"
                to={ `/blog/category/${ p.post.category.slug.current}` }
                >
                  { p.post.category.name }
                </Link>
              }
            </div>

            <h2
            className="title h-md"
            itemprop="title"
            >
              <Link
              className="d-inline-block"
              to={ `/blog/${p.post.slug.current}` }
              rel="bookmark"
              itemprop="url"
              >
                { p.post.title }
              </Link>
            </h2>
            <div
            className="author p-md"
            itemprop="author"
            itemscope
            itemtype="https://schema.org/Person"
            >
             { /*}<a rel="author" itemprop="url" href="#" title="View author biography">*/ }
               By <span itemprop="name">{ !! p.post.author ? p.post.author.name : '' }</span>
             { /*}</a>*/ }
           </div>
          </header>

          <div
          className="summary p-md"
          itemprop="description"
          >
            { p.post.summary }
          </div>
          <Link
          className="more f-more d-inline-block"
          to={ `/blog/${p.post.slug.current}` }
          aria-label={ `Continue reading ${p.post.title }` }
          >
            Keep reading
          </Link>
        </div>

      </div>
    </article>

  )

};
MajorPostSnippet.propTypes = propTypes;

export default MajorPostSnippet;
